import R from 'ramda'

import ProjectTitle from '@/components/ProjectTitle.vue'
import FokoQuery from '@/components/Query.vue'

import QueryMixin from '@/mixins/query'

const queries = [{
  title: 'Kunstobjekte aus Holz',
  query: '+material_t:Holz'
}, {
  title: 'Kunstobjekte aus Sandstein',
  query: '+material_t:Sandstein'
}, {
  title: 'Kunstobjekte des Barock',
  query: '+feature_t:Barock'
}, {
  title: 'Kunstobjekte aus der Ukraine',
  query: '+country_t:Ukraine'
}, {
  title: 'Kunstobjekte aus Polen',
  query: '+country_t:Polen'
}, {
  title: 'Kunstobjekte aus Ungarn',
  query: '+country_t:Ungarn'
}]

export default {
  name: 'foko-home',

  mixins: [ QueryMixin ],
  components: { FokoQuery, ProjectTitle },

  metaInfo: {
    title: 'Homepage'
  },

  data () {
    return {
      galleryLoading: true,
      gallery: [],
      galleryIndex: 0
    }
  },

  computed: {
    galleryTitle () {
      const { galleryIndex, gallery } = this
      if (galleryIndex >= gallery.length) return ''
      const { name, country, city } = gallery[galleryIndex]
      const location = R.pipe(R.reject(R.isNil), R.join(', '))([country, city])
      return R.pipe(R.reject(R.isNil), R.join(' – '))([name, location])
    },

    images () {
      return this.gallery.reduce(
        (thumbs, { id, image }) => Object.assign(thumbs, {
          [id]: `/iiif/${image}.tif/square/400,/0/default.jpg`
        }),
        {}
      )
    },
    swiperOptions () {
      return {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 30
        },
        autoplay: {
          delay: 3000,
          waitForTransition: false
        },
        centeredSlides: true,
        slidesPerView: 'auto',
        initialSlide: 5
      }
    }
  },

  methods: {
    async loadGallery () {
      this.galleryLoading = true
      this.galleryIndex = 0
      this.gallery = []
      try {
        const { query } = queries[Math.floor(Math.random() * queries.length)]
        const params = {
          query: [ query, '-image_count_i:0' ].join(' AND '),
          limit: 50
        }
        const response = await this.$http.get('/api/search', { params })
        const results = await response.json()
        this.gallery = results.results
      } catch (e) {
        // ignore
      }

      this.galleryLoading = false
    },

    slideChanged () {
      this.galleryIndex = this.$refs.swiper.swiperInstance.realIndex
    },

    refreshSlides () {
      this.loadGallery()
    }
  },

  created () {
    this.loadGallery()
  },

  mounted () {
    this.executeQuery()
  },

  watch: {
    $route () {
      this.executeQuery()
    }
  }
}
