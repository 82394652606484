import { nt, select, p, patterns } from '../lib/sparql'

const apiSampleQuery = select({
  variables: ['?object', '?country', '?city', '?title', '?iconclass', '?artist'],
  distinct: true,
  where: [patterns(
    nt('?object', 'rdf:type', 'foko:Object'),

    nt('?object', p(
      'ecrm:P102_has_title',
      'ecrm:P3_has_note'
    ), '?title'),

    nt('?object', p(
      'ecrm:P53_has_former_or_current_location',
      'ecrm:P87_is_identified_by'
    ), '?location'),

    nt('?location', p(
      'ecrm:P87i_identifies',
      'ecrm:P87_is_identified_by',
      'ecrm:P3_has_note'
    ), '?city'),

    nt('?location', 'foko:has_country', '?country'),

    nt('?object', p(
      'ecrm:P108i_was_produced_by',
      'ecrm:P14_carried_out_by',
      'ecrm:P131_is_identified_by',
      'ecrm:P3_has_note'
    ), '?artist'),

    nt('?object', 'ecrm:P1_is_identified_by', '?type'),
    nt('?type', 'ecrm:P3_has_note', '?iconclass')
  ), {
    'type': 'filter',
    'expression': {
      'type': 'operation',
      'operator': 'regex',
      'args': [
        '?iconclass',
        '"^11HH"'
      ]
    }
  }],
  order: [
    { expression: '?object' },
    { expression: '?country' },
    { expression: '?city' },
    { expression: '?title' },
    { expression: '?iconclass' },
    { expression: '?artist' }
  ],
  limit: 1000
})

export default {
  name: 'navigation',

  data () {
    return {
      apiUrl: `/api/#query=${encodeURIComponent(apiSampleQuery)}`
    }
  }
}
