import Colophon from '@/components/Colophon.vue'

const title = 'FoKO – Forschungsinfrastruktur Kunstdenkmäler in Ostmitteleuropa'
export default {
  name: 'app',
  components: { Colophon },

  metaInfo () {
    return {
      title,
      titleTemplate: `%s | ${title}`,
      htmlAttrs: {
        lang: 'de',
        'class': this.modalOpen ? 'modal-open' : ''
      }
    }
  },

  data () {
    return { modalOpen: false }
  },

  created () {
    this.eventBus().$on('modal-open', (modalOpen) => { this.modalOpen = modalOpen })
  }
}
