import FokoQuery from '@/components/Query.vue'
import QueryMixin from '@/mixins/query'

export default {
  name: 'foko-objects',

  props: ['q', 'view', 'id'],

  mixins: [ QueryMixin ],
  components: { FokoQuery },

  metaInfo: {
    title: 'Objekte'
  },

  data: () => ({
    queryVisible: true
  }),

  computed: {
    queryWaypoint () {
      const callback = ({ going }) => {
        this.queryVisible = (going === 'in')
      }
      return { active: !this.loading, callback }
    },

    endOfResults () {
      return {
        active: !this.loading,
        callback: this.endOfResultsReached.bind(this),
        options: { rootMargin: '25%' }
      }
    }
  },

  methods: {
    endOfResultsReached ({ direction, going }) {
      if (direction && going === 'in') {
        this.limit *= 2
      }
    },

    scrollToObject () {
      if (!this.id) return

      this.$nextTick(() => {
        const objectEl = this.$el.querySelector(`[data-id=${this.id}]`)
        if (!objectEl) return

        this.$scrollTo(objectEl, 500, { offset: -2 * this.$el.offsetTop })
      })
    }
  },

  watch: {
    $route () {
      this.executeQuery()
    },
    objects () {
      this.scrollToObject()
    }
  },

  activated () {
    this.scrollToObject()
  },

  mounted () {
    this.executeQuery()
  }
}
