import { context, pn } from '../lib/ns'
import { nt } from '../lib/sparql'

import schemaData from './wisski-schema.json'

const { assign } = Object

class Entity {
  constructor (data, schema) {
    assign(this, data)
    this.schema = schema
  }

  toString () {
    const { name, description, id } = this
    return name || description || `<${id}>`
  }

  pathUris (type) {
    const filter = type ? seg => seg.type === type : () => true
    return this.path.filter(filter).map(seg => seg.uri)
  }

  rdfType () {
    const { path } = this
    const { length } = path
    return length === 0 ? undefined : path[length - 1].uri
  }

  patterns (subject = 'subject', value = 'value', prefix = 's') {
    const ph = (i) => (i <= 0) ? subject : (prefix + i.toString())

    const { path, dataProperty } = this
    const { length } = path

    return path.map((seg, i) => {
      const { type, uri } = seg
      switch (type) {
        case 'type':
          return nt(`?${ph(i - 1)}`, 'rdf:type', uri)
        case 'property':
          return nt(`?${ph(i - 2)}`, uri, `?${ph(i)}`)
      }
      throw new Error(type)
    }).concat([nt(`?${ph(length - 2)}`, dataProperty, `?${value}`)])
  }

  entityPath (root) {
    root = root ? root.id : undefined

    const ep = [ this ]
    let parent = this.parent
    while (parent) {
      const parentEntity = this.schema.byId[parent]
      ep.unshift(parentEntity)
      if (parent === root) {
        break
      }
      parent = parentEntity.parent
    }
    return ep
  }

  parentEntity () {
    return this.parent ? this.schema.byId[this.parent] : undefined
  }

  parentPathExtension () {
    const parent = this.parentEntity()
    const { path } = this
    if (!parent || parent.path.length === 0) {
      return undefined
    } else if (path.length === 0) {
      return []
    } else {
      return path.slice(parent.path.length - 1)
    }
  }

  frame (explicitTypes = [], parentFrame) {
    const { parent, id, dataProperty } = this
    let frame = parentFrame || {
      '@context': context,
      '@type': this.rdfType()
    }
    if (parent && parentFrame) {
      const pe = this.parentPathExtension().slice(1)
      for (let pei = 0, pl = pe.length; pei < pl; pei += 2) {
        const [property, type] = [pe[pei], pe[pei + 1]]
          .map(({ uri }) => uri)
          .map(pn)
        frame = frame[property] = frame[property] || {}
        if (explicitTypes.indexOf(type) >= 0) {
          frame['@explicit'] = true
        }
      }
    }
    if (dataProperty) {
      const property = pn(dataProperty)
      frame[property] = frame[property] || {}
    }

    const schemaRef = frame['foko:schema'] = frame['foko:schema'] || {
      '@default': []
    }
    schemaRef['@default'].push(id)

    // this.children().forEach(child => child.frame(explicitTypes, frame));

    return frame
  }

  children () {
    const { id, schema } = this
    return (schema.byParent[id] || []).filter(schema.predicate())
  }

  descendants () {
    const descendants = []
    this.schema.traverse(this, (e) => descendants.push(e))
    return descendants.slice(1)
  }
}

function merge (idx, key, value, multiple = false) {
  if (key && value) {
    if (multiple) {
      idx[key] = (idx[key] || []).concat([ value ])
    } else {
      idx[key] = value
    }
  }
  return idx
}

export class Schema {
  static fromJson (str) {
    return new Schema(JSON.parse(str))
  }

  json () {
    return this.entities.map(e => assign({}, e, { schema: undefined }))
  }

  constructor (entities = []) {
    this.entities = entities.map(e => new Entity(e, this))

    this.byId = this.entities.reduce((idx, e) => merge(idx, e.id, e), {})
    this.byParent = this.entities.reduce((idx, e) => merge(idx, e.parent, e, true), {})

    for (const parent in this.byParent) {
      this.byParent[parent].sort((a, b) => a.weight - b.weight)
    }

    this.byType = {}
    this.byProperty = {}
    this.entities.forEach(e => e.path.forEach((seg) => {
      const { type, uri } = seg
      let index
      switch (type) {
        case 'type':
          index = this.byType
          break
        case 'property':
          index = this.byProperty
          break
      }
      if (index) {
        assign(
          index[uri] = index[uri] || {},
          { [e.id]: e }
        )
      }
    }))

    this.filters = [ () => true ]
  }

  predicate () {
    return (e) => this.filters.every(f => f(e))
  }

  onlyActive () {
    this.filters.push(e => e.active)
    return this
  }

  roots (names) {
    return this.entities
      .filter(p => !p.parent)
      .filter(this.predicate())
      .filter(p => names ? names.indexOf(p.name) >= 0 : true)
  }

  all () {
    return this.entities.filter(this.predicate())
  }

  traverse (root, callback, hierarchy = [], predicate) {
    predicate = predicate || this.predicate()
    if (predicate(root)) {
      callback(root, hierarchy)
      hierarchy.unshift(root)
      root.children().forEach(
        child => this.traverse(child, callback, hierarchy, predicate)
      )
      hierarchy.shift()
    }
  }
}

export const schema = () => new Schema(schemaData)
