import Vue from 'vue'
import Router from 'vue-router'

import Info from '@/views/Info.vue'
import Home from '@/views/Home.vue'
import List from '@/views/List.vue'
import Map from '@/views/Map.vue'
import FokoObject from '@/views/Object.vue'

Vue.use(Router)

const props = ({ params, query }) => ({ ...params, ...query })

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'root',
      path: '/',
      redirect: '/de'
    },
    {
      name: 'home',
      path: '/:lang',
      component: Home
    },
    {
      name: 'info',
      path: '/info/:lang',
      component: Info
    },
    {
      name: 'list',
      path: '/list/:lang',
      component: List,
      props
    },
    {
      name: 'map',
      path: '/map/:lang',
      component: Map,
      props
    },
    {
      name: 'object',
      path: '/object/:lang/:id',
      component: FokoObject,
      props
    },
    {
      name: 'default',
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior (to, from, saved) {
    if (saved) return saved

    const { hash } = to
    if (hash) return { selector: hash }

    return { x: 0, y: 0 }
  }
})
