import { pn } from '@/lib/ns'
import { address, coordinates } from '@/lib/location'

const path = (entity) => {
  return (entity.parentPathExtension() || [])
    .filter(({ type }) => type === 'property')
    .map(({ uri }) => pn(uri))
}

const type = (entity) => pn(entity.rdfType())

const Debug = {
  name: 'foko-debug',
  props: ['model'],

  render (createElement) {
    return createElement('pre', [ JSON.stringify(this.model, null, 2) ])
  }
}

const Note = {
  name: 'foko-note',
  props: ['model'],

  render (createElement) {
    return createElement(
      'span', { 'class': 'foko-note' },
      [this.ps(this.model, 'ecrm:P3_has_note')]
    )
  }
}

const Link = {
  name: 'foko-link',
  props: ['model'],

  render (createElement) {
    const href = this.ps(this.model, 'ecrm:P3_has_note')
    return createElement(
      'a', { 'class': 'foko-link', attrs: { target: '_blank', href } }, [ href ]
    )
  }
}

const TimeSpan = {
  name: 'foko-time-span',
  props: ['model'],

  render (createElement) {
    return createElement('p', [this.ps(this.model, 'ecrm:P82_at_some_time_within')])
  }
}

const Location = {
  name: 'foko-location',
  props: ['model'],

  render (createElement) {
    const { model } = this
    const text = address(model)
    const coords = coordinates(model)
    if (coords) {
      const { lat, lon } = coords
      const [lt, ln] = [lat, lon].map(encodeURIComponent)
      const href = `//www.openstreetmap.org/?mlat=${lt}&mlon=${ln}#map=17/${lt}/${ln}`
      return createElement(
        'a', { 'class': 'foko-location', attrs: { target: '_blank', href } }, [ text ]
      )
    }

    return createElement('span', { 'class': 'foko-location' }, [ text ])
  }
}

const isTranslated = (type) => ['Appellation', 'Name', 'Title', 'ID', 'Number', 'Description']
  .some(sfx => type.endsWith(`_${sfx}`))

const Translation = {
  name: 'foko-translation',
  props: ['model'],

  render (createElement) {
    const { model } = this
    const components = [ createElement(Note, { props: { model } }) ]

    const lang = this.ps(
      model, 'ecrm:P72_has_language', 'ecrm:P1_is_identified_by', 'ecrm:P3_has_note'
    )
    if (lang) {
      // components.push(createElement('span', { 'class': 'tag' }, [ lang ]))
    }
    return createElement(
      'p', { 'class': 'foko-translation', attrs: { 'title': lang } }, components
    )
  }
}

const Reference = {
  name: 'foko-reference',
  props: ['model'],

  render (createElement) {
    const { model } = this
    const id = this.ps(model, 'ecrm:P3_has_note')
    const file = this.ps(
      model, 'ecrm:P71i_is_listed_in', 'ecrm:P149_is_identified_by', 'ecrm:P3_has_note'
    )
    const uid = encodeURIComponent(id)
    let href
    switch (file.toLowerCase()) {
      case 'aat':
        href = `http://vocab.getty.edu/aat/${uid}`
        break
      case 'gnd':
        href = `http://d-nb.info/gnd/${uid}`
        break
      case 'iconclass':
        href = `http://iconclass.org/${uid}`
        break
      case 'tdk':
        // `http://dk.bu.uni.wroc.pl/tdk/thesaurus/${uid}`
        href = `http://156.17.58.20:8080/tezaurus/displayDocument.htm?docId=${uid}`
        break
      case 'geonames':
        href = `https://www.geonames.org/${uid}/`
        break
    }

    let refElement = createElement('span', { 'class': 'tag is-link' }, [ file ])

    if (href) {
      refElement = createElement(
        'a', { 'class': 'tag is-link', attrs: { href, target: '_blank' } }, [ file ]
      )
    }

    return createElement('span', { 'class': 'foko-reference tags has-addons' }, [
      createElement(Note, { class: 'tag', props: { model } }), refElement
    ])
  }
}

const LiteratureSpot = {
  name: 'foko-literature-spot',
  props: ['model'],

  render (createElement) {
    const { model } = this
    // eslint-disable-next-line
    const spot = createElement(Note, { props: { model } })

    return createElement('foko-node', { props: {
      model: this.p(model, 'ecrm:P106i_forms_part_of'),
      entity: this.schema().roots('Literatur').shift()
    } })
  }
}

const FokoValue = {
  name: 'foko-value',
  props: ['entity', 'model'],

  render (createElement) {
    let { entity, model } = this
    const entityType = type(entity)

    switch (entityType) {
      case 'foko:Literature_Spot':
        return createElement(
          'div',
          model.map(m => createElement(LiteratureSpot, { props: { model: m } }))
        )
      case 'foko:Location':
        return createElement(Location, { props: { model } })
      case 'ecrm:E58_Measurement_Unit':
      case 'foko:Transcription':
      case 'foko:Section_Definition_Inscription':
      case 'foko:Additional_Sources':
      case 'foko:Photo_Connection':
      case 'foko:Expression-ID':
      case 'foko:View':
      case 'foko:Perspective':
        return createElement(Note, { props: { model } })
      case 'ecrm:E51_Contact_Point':
        return createElement(Link, { props: { model } })
      case 'ecrm:E52_Time-Span':
        return createElement(TimeSpan, { props: { model } })
      case 'foko:History_and_Description':
      case 'ecrm:E33_Linguistic_Object':
        return createElement('div', {
          'class': 'foko-linguistic-object content',
          'domProps': {
            innerHTML: this.ps(model, 'ecrm:P3_has_note')
          }
        })
      case 'foko:Authority_File_ID':
        return createElement(
          'div',
          model.map(m => createElement(Reference, { props: { model: m } }))
        )
    }

    if (isTranslated(entityType)) {
      model = this.sortedTranslations(model)
      return createElement(
        'div',
        model.map(m => createElement(Translation, { props: { model: m } }))
      )
    }
    if (entity.children().length > 0) {
      return createElement('foko-node', { props: { entity, model } })
    }
    return createElement(Debug, { props: { model } })
  }
}

export default {
  name: 'foko-node',
  props: ['entity', 'model'],
  components: { FokoValue },

  computed: {
    description () {
      return this.entity.toString()
    },

    leaf () {
      return this.entity.children().length === 0
    },

    path () {
      return path(this.entity).join('/')
    },

    type () {
      return type(this.entity)
    },

    headerClass () {
      return {
        'subtitle': true,
        'has-text-weight-bold': this.entity.group
      }
    }
  },

  methods: {
    pathFilter (entity, model) {
      if (model['@type'] !== type(entity)) return false

      const { dataProperty } = entity
      if (dataProperty && !this.ps(model, [pn(dataProperty)])) return false

      return true
    },

    modelFilter ({ id, entity, model }) {
      if (model.length === 0) {
        return false
      }

      switch (entity.id) {
        case '39': // manifestation count
        case '255': // manifestation schema duplicate
        case '260': // dimensions
        case '280': // expression -> object
        case '281': // manifestation -> object
        case '721': // expression -> expression
        case '808': // expression ID
        case '809': // manifestation -> expression
        case '814': // expression -> expression
        case '1014': // object classification
        case '1015': // object classification
        case '1029': // object production; material
        case '1030': // object production; technique
        case '1031': // object: iconographic classification
        case '1035': // expression classification
        case '1036': // expression classification
        case '1038': // expression classification
        case '1040': // manifestation schema duplicate
        case '1041': // manifestation schema duplicate
        case '1042': // manifestation schema duplicate
        case '1043': // manifestation schema duplicate
          return false
      }

      return true
    },

    children (model) {
      return this.entity.children().map(c => ({

        id: c.id,
        entity: c,
        model: this.p(model, ...path(c)).filter(m => this.pathFilter(c, m))

      })).filter(this.modelFilter)
    }
  }
}
