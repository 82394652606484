export default {
  data: () => ({
    cookiesAccepted: false
  }),

  storage: {
    keys: ['cookiesAccepted'],
    namespace: 'foko'
  },

  methods: {
    acceptCookies () {
      this.cookiesAccepted = true
    }
  }
}
