import R from 'ramda'

import * as model from '@/lib/model-helper'

export default {
  methods: {
    p (values, ...path) {
      return model.path(path, values)
    },

    ps (values, ...path) {
      return R.head(this.p(values, ...path))
    }
  }
}
