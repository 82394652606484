import OpenSeadragon from 'openseadragon'

export default {
  name: 'image-viewer',
  props: ['images'],

  data () {
    return {
      index: 0,
      metadataActive: false
    }
  },

  computed: {
    image () {
      const { index, images } = this
      return index < images.length ? images[index] : undefined
    },
    title () {
      if (!this.image) return undefined

      const { expression } = this.image

      const content = this.p(
        expression, 'ecrm:P2_has_type', 'ecrm:P70i_is_documented_in', 'ecrm:P3_has_note'
      )
      const views = this.p(
        expression, 'ecrm:P2_has_type', 'ecrm:P3_has_note'
      )

      const description = content.concat(views).join(', ')

      const createdAt = this.p(
        expression, 'ecrm:P94i_was_created_by', 'ecrm:P7_took_place_at'
      )
      const city = this.p(createdAt, 'ecrm:P87_is_identified_by', 'ecrm:P3_has_note').slice(0, 1)
      const country = this.p(
        createdAt, 'ecrm:P89_falls_within', 'ecrm:P87_is_identified_by', 'ecrm:P3_has_note'
      ).slice(0, 1)
      const date = this.p(
        expression,
        'ecrm:P94i_was_created_by', 'ecrm:P4_has_time-span', 'ecrm:P82_at_some_time_within'
      )
      const author = this.p(
        expression,
        'ecrm:P94i_was_created_by', 'ecrm:P14_carried_out_by',
        'ecrm:P131_is_identified_by', 'ecrm:P3_has_note'
      ).slice(0, 1)

      const creation = [
        city, country, author, date
      ].reduce((all, one) => all.concat(one), []).join(' – ')

      return [description, creation].join(' | ')
    }
  },

  watch: {
    images () {
      this.index = 0
      this.metadataActive = false
      this.updateViewer()
    },
    metadataActive () {
      this.eventBus().$emit('modal-open', this.metadataActive)
    }
  },

  methods: {
    expressionSchema () {
      return this.schema().roots('Expression').shift()
    },

    manifestationSchema () {
      return this.schema().roots('Manifestation').shift()
    },

    toggleMetadata () {
      this.metadataActive = !this.metadataActive
    },

    updateViewer () {
      const { images } = this

      if (images.length === 0) {
        this.destroyViewer()
      }

      if (images.length > 0) {
        if (!this.viewer) {
          const ajaxWithCredentials = process.env.NODE_ENV === 'production'
          this.viewer = window.osd = OpenSeadragon({
            element: this.$refs.canvas,
            sequenceMode: true,
            showNavigationControl: false,
            showNavigator: true,
            showSequenceControl: false,
            showReferenceStrip: true,
            referenceStripSizeRatio: 0.1,
            referenceStripScroll: 'vertical',
            crossOriginPolicy: ajaxWithCredentials ? 'use-credentials' : false,
            ajaxWithCredentials
          })
          this.viewer.addHandler('page', ({ page }) => { this.index = page })
        }
        this.viewer.open(this.images.map(({ image }) => `/iiif/${image}.tif/info.json`))
      }
    },

    destroyViewer () {
      if (this.viewer) {
        this.viewer.destroy()
        this.viewer = undefined
      }
    },

    zoomIn () {
      this.zoom(1.5)
    },

    zoomOut () {
      this.zoom(0.75)
    },

    nextImage () {
      this.viewer.goToPage(Math.min(this.images.length - 1, this.index + 1))
    },

    prevImage () {
      this.viewer.goToPage(Math.max(0, this.index - 1))
    },

    zoom (ratio) {
      if (this.viewer) {
        this.viewer.viewport.zoomBy(ratio)
      }
    }
  },

  mounted () {
    this.updateViewer()
  },

  beforeDestroy () {
    this.destroyViewer()
  }
}
