import * as wisski from '../backend/wisski'

const schema = wisski.schema().onlyActive()

export default {
  methods: {
    schema () {
      return schema
    }
  }
}
