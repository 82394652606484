import R from 'ramda'
import * as model from '../lib/model-helper'

const langs = ['de', 'en', 'pl', 'fr']
const langOrder = R.fromPairs(langs.map((l, li) => [l, li + 1]))
const langSorter = R.sortBy((a) => (langOrder[a] || langs.length))

export default {
  computed: {
    lang () {
      return this.$route.params.lang || 'de'
    },
    tr () {
      return model.translate(this.lang, 'de')
    }
  },
  methods: {
    sortedTranslations (values) {
      const translations = R.groupBy(
        R.pipe(
          model.path(
            ['ecrm:P72_has_language', 'ecrm:P1_is_identified_by', 'ecrm:P3_has_note']
          ),
          R.map(R.toLower),
          R.head,
          R.defaultTo('xx')
        ),
        values
      )
      const languages = langSorter(Object.keys(translations))
      return R.map(l => translations[l], languages).reduce((all, one) => all.concat(one))
    }
  }
}
