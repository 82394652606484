import Vue from 'vue'

import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'

import VueMeta from 'vue-meta'
import VueResource from 'vue-resource'
import VueScrollTo from 'vue-scrollto'
import VueLazyload from 'vue-lazyload'
import VueWaypoint from 'vue-waypoint'
import VueStorage from 'vuejs-storage'
import VueSwiper from 'vue-awesome-swiper'
import VueMatomo from 'vue-matomo'
import { focus } from 'vue-focus'
import resize from 'vue-resize-directive'

import App from '@/App.vue'
import router from '@/router'

import EventBusMixin from '@/mixins/eventbus'
import LangMixin from '@/mixins/lang'
import SchemaMixin from '@/mixins/schema'
import ModelMixin from '@/mixins/model'
import RouterMixin from '@/mixins/router'
import ScrollMixin from '@/mixins/scroll.js'
import CookiesMixin from '@/mixins/cookies.js'

import FokoNavigation from '@/components/Navigation.vue'
import FokoNode from '@/components/FokoNode.vue'

import '@/assets/sass/app.scss'
import cidocCrmTranslations from '@/assets/i18n/cidoc-crm.json'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowDown, faArrowLeft, faArrowRight, faArrowsRotate, faArrowUp, faDownload, faEarthEurope, faFilter, faGears, faHome, faInfo, faLink,
  faList, faMap, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
// import {  } from '@fortawesome/free-regular-svg-icons'
library.add(faArrowDown, faArrowLeft, faArrowRight, faArrowsRotate, faArrowUp, faDownload, faEarthEurope, faFilter, faGears, faHome, faInfo, faLink,
  faList, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faMap, faSearch, faXmark)

Vue.use(VueI18Next)
Vue.use(VueMeta)
Vue.use(VueResource)
Vue.use(VueScrollTo)
Vue.use(VueLazyload)
Vue.use(VueStorage)
Vue.use(VueWaypoint)
Vue.use(VueSwiper)

const productionEnv = process.env.NODE_ENV === 'production'
if (productionEnv) {
  Vue.use(VueMatomo, {
    host: 'https://www.herder-institut.de/piwik/',
    siteId: 7,
    router
  })
}

Vue.directive('focus', focus)
Vue.directive('resize', resize)

Vue.mixin(EventBusMixin)
Vue.mixin(LangMixin)
Vue.mixin(SchemaMixin)
Vue.mixin(ModelMixin)
Vue.mixin(RouterMixin)
Vue.mixin(ScrollMixin)
Vue.mixin(CookiesMixin)

Vue.component('foko-navigation', FokoNavigation)
Vue.component('foko-node', FokoNode)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productiontip = false
Vue.http.options.credentials = true

i18next.init({
  lng: 'de',
  fallbackLng: 'de',
  debug: !productionEnv
})

for (const lang in cidocCrmTranslations) {
  i18next.addResourceBundle(lang, 'cidoc', cidocCrmTranslations[lang])
}

const el = '#app'

const i18n = new VueI18Next(i18next)

const render = (h) => h(App)

window.fokoApp = new Vue({ el, i18n, router, render })
