import Awesomplete from 'awesomplete'

import QueryMixin from '@/mixins/query'
import FokoWordCloud from '@/components/WordCloud.vue'

const completion = ({ value }) => {
  let txt = value.toString()
  txt = txt.replace(/<\/?b>/g, '')
  if (txt.search(/\W/g) >= 0) txt = `"${txt}"`
  return txt
}

const fields = [
  'title', 'name', 'type',
  'city', 'country', 'address',
  'feature', 'technique', 'material',
  'person', 'workshop', 'institution', 'event',
  'use', 'status'
]

export default {
  name: 'foko-query',

  props: ['view'],

  mixins: [ QueryMixin ],
  components: { FokoWordCloud },

  data: () => ({
    textQuery: '',
    facetsActive: false
  }),

  watch: {
    $route () {
      this.textQuery = this.$route.query.q || ''
      this.awesomplete && this.awesomplete.close()
    },

    facetsActive () {
      this.eventBus().$emit('modal-open', this.facetsActive)
    }
  },

  created () {
    this.textQuery = this.$route.query.q || ''
  },

  mounted () {
    const { input } = this.$refs

    const awesomplete = this.awesomplete = new Awesomplete(input, {
      container: (input) => input.parentNode,
      replace: (selected) => { this.textQuery = completion(selected) },
      filter: () => true
    })

    input.addEventListener('input', async (e) => {
      const query = input.value.trim()
      if (query.length > 2) {
        const limit = 10
        const params = { query, limit }
        const response = await this.$http.get('/api/suggest', { params })

        const { suggestions } = await response.json()
        const list = []
        for (const field of fields) {
          for (const value of (suggestions[field] || [])) {
            const label = `${value} — <i>${this.$t(field)}</i>`
            list.push({ label, value })
          }
        }

        awesomplete.list = list
        awesomplete.evaluate()
      }
    })
  },

  beforeDestroy () {
    this.awesomplete.destroy()
  },

  methods: {
    toggleFacets () {
      this.facetsActive = !this.facetsActive
    },

    facetClicked (type, { value }) {
      this.facetsActive = false
      this.$nextTick(() => this.$router.push(this.facetsRoute(
        this.objectsRoute(undefined, this.view),
        { [type]: value }
      )))
    }
  }
}
