import R from 'ramda'

const empty = R.isNil

export default {
  computed: {
    homeRoute () {
      return { ...this.baseRoute(), name: 'home' }
    }
  },

  methods: {
    infoRoute (hash) {
      return { ...this.baseRoute(), name: 'info', hash }
    },

    baseRoute () {
      const { name, params, query } = this.$route
      return { name, params, query }
    },

    normalizeRoute (route) {
      const { name, params, query } = route
      return {
        name,
        params: R.reject(empty, params),
        query: R.reject(empty, query)
      }
    },

    resetRoute () {
      return this.normalizeRoute({ ...this.baseRoute(), query: {} })
    },

    objectRoute (id) {
      const base = this.baseRoute()
      return this.normalizeRoute({
        ...base,
        name: 'object',
        params: { ...base.params, id },
        query: { ...base.query, id }
      })
    },

    objectsRoute (q, name) {
      const base = this.baseRoute()
      return this.normalizeRoute({
        ...base,
        name: name || 'list',
        query: { ...base.query, q: (q !== undefined ? q : base.query.q) }
      })
    },

    langRoute (lang = 'de') {
      const base = this.baseRoute()
      return this.normalizeRoute({ ...base, params: { ...base.params, lang } })
    }

  }
}
