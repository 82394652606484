import Vue from 'vue'

const eventBus = new Vue()

export default {
  methods: {
    eventBus () {
      return eventBus
    }
  }
}
