import R from 'ramda'

import { objectImages } from '@/lib/image'
import { objectAddress } from '@/lib/location'

import ImageViewer from '@/components/ImageViewer.vue'

export default {
  name: 'foko-object',

  props: ['id', 'q', 'view'],

  components: { ImageViewer },

  metaInfo () {
    return R.reject(R.isNil, {
      title: this.loading ? '…' : this.name()
    })
  },

  data: () => ({
    loading: true,
    modelVisible: false,
    headerVisible: true,
    images: []
  }),

  computed: {
    jsonLd () {
      return `/objects/${this.id}.ld.json`
    },

    wisski () {
      return `http://foko2014.gnm.de/content/${this.id}`
    },

    headerWaypoint () {
      const callback = ({ direction, going }) => {
        this.headerVisible = going === 'in'
      }
      return { active: !this.loading, callback }
    }
  },

  methods: {
    async load () {
      this.loading = true
      const response = await this.$http.get(this.jsonLd)
      this.object = window.fokoObject = await response.json()
      this.images = objectImages(this.o())
      this.loading = false
    },

    name () {
      return this.tr(this.o('ecrm:P1_is_identified_by'))
    },

    title () {
      return this.tr(this.o('ecrm:P102_has_title'))
    },

    location () {
      return objectAddress(this.o())
    },

    o (...path) {
      return this.p(this.object, ...path)
    },

    os (...path) {
      return this.ps(this.object, ...path)
    },

    objectSchema () {
      return this.schema().roots('Objekt').shift()
    },

    scrollToModel () {
      this.$scrollTo(this.$refs.model, 500)
    }
  },

  watch: {
    $route () {
      this.load()
    }
  },

  created () {
    this.load()
  }
}
