import L from 'leaflet'
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers'
import 'leaflet.markercluster/dist/leaflet.markercluster-src'

import FokoQuery from '@/components/Query.vue'

import QueryMixin from '@/mixins/query'

L.Icon.Default.imagePath = 'leaflet/images/'

const ObjectMarker = L.Marker.extend({ options: { fokoObject: {} } })

const mapTiles = {
  cartodb: {
    src: '//{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
    // src: "//cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
    attrib: [
      '&copy;',
      '<a href="//www.openstreetmap.org/copyright">OpenStreetMap</a>',
      'contributors,',
      '&copy; <a href="//cartodb.com/attributions">CartoDB</a>'
    ].join(' '),
    maxZoom: 19
  },
  stamen: {
    src: '//stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg',
    attrib: [
      'Map tiles by <a href="//stamen.com">Stamen Design</a>,',
      'under <a href="//creativecommons.org/licenses/by/3.0">CC BY 3.0</a>.',
      'Data by <a href="//openstreetmap.org">OpenStreetMap</a>,',
      'under <a href="//www.openstreetmap.org/copyright">ODbL</a>.'
    ].join(' '),
    maxZoom: 13
  },
  osm: {
    // src: '//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    // src: '//maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
    src: '//tile.openstreetmap.org/{z}/{x}/{y}.png',
    attrib: [
      'Karte hergestellt aus <a href="//openstreetmap.org">OpenStreetMap</a>-Daten |',
      'Lizenz: <a href="https://opendatacommons.org/licenses/odbl/">Open Database License (ODbL)</a>'
    ].join(' '),
    maxZoom: 19
  }
}

export default {
  name: 'foko-map',

  props: ['q', 'id'],

  mixins: [ QueryMixin ],
  components: { FokoQuery },

  metaInfo: {
    title: 'Karte'
  },

  watch: {
    $route () {
      this.executeQuery()
    },
    id () {
      this.renderObjects()
    },
    objects () {
      this.renderObjects()
    }
  },

  mounted () {
    this.executeQuery()
  },

  activated () {
    if (this.map) {
      this.map.invalidateSize()
    }
  },

  beforeDestroy () {
    if (this.map) {
      this.map.remove()
    }
  },

  methods: {
    renderObjects () {
      if (!this.map) {
        this.objectLayer = L.markerClusterGroup({ disableClusteringAtZoom: 15 })
        this.objectLayer.on('click', (e) => {
          const { options } = e.layer || {}
          const { fokoObject } = options || {}

          if (fokoObject) {
            const id = fokoObject
            this.$router.push(this.objectRoute(id))
          }
        })

        const { src, attrib, maxZoom } = mapTiles.osm
        this.map = L.map(this.$refs.map, {
          center: [52, 25],
          zoom: 6,
          maxZoom,
          layers: [
            this.objectLayer,
            L.tileLayer(src, { attribution: attrib, maxZoom })
          ]
        })
      }

      let currentObject = null
      const objectsBounds = L.latLngBounds()

      this.objectLayer.clearLayers()
      this.objects
        .filter(({ coords }) => coords != null)
        .forEach(({ id, coords, title, name, image, country, city }) => {
          const objectMarker = new ObjectMarker(coords, {
            icon: L.AwesomeMarkers.icon({
              prefix: 'fa',
              icon: image ? 'photo' : 'info',
              markerColor: this.id === id ? 'red' : 'green'
            }),
            fokoObject: id,
            title: [
              name, title,
              [city, country].filter(c => c).join(', ')
            ].filter(c => c).join('\n')
          })
          this.objectLayer.addLayer(objectMarker)
          if (this.id === id) {
            currentObject = objectMarker
          }
          objectsBounds.extend(coords)
        })

      if (currentObject) {
        this.objectLayer.zoomToShowLayer(currentObject)
        this.map.flyTo(currentObject.getLatLng())
      } else if (objectsBounds.isValid()) {
        this.map.fitBounds(objectsBounds, { padding: [20, 20] })
      }
    }
  }
}
