import R from 'ramda'
import lucene from 'lucene'

export default {
  i18nOptions: {
    messages: {
      de: {
        'title': 'Titel',
        'name': 'Name',
        'status': 'Zustand',
        'person': 'Person',
        'workshop': 'Werkstatt',
        'institution': 'Institution',
        'event': 'Ereignis',
        'city': 'Stadt',
        'address': 'Anschrift',
        'country': 'Land',
        'feature': 'Stil',
        'material': 'Material',
        'technique': 'Technik',
        'type': 'Klassifikation',
        'use': 'Funktion'
      },
      en: {
        'title': 'Title',
        'name': 'Name',
        'status': 'Status',
        'person': 'Person',
        'workshop': 'Workshop',
        'Institution': 'Institution',
        'event': 'Event',
        'city': 'City',
        'address': 'Address',
        'country': 'Country',
        'feature': 'Feature',
        'material': 'Material',
        'technique': 'Technique',
        'type': 'Type',
        'use': 'Use'
      }
    }
  },

  data: () => ({
    results: {},
    limit: 2000,
    loading: false
  }),

  computed: {
    facetTypes () {
      return ['feature', 'material', 'type', 'use', 'country', 'technique']
    },
    facets () {
      const { query } = this.$route
      return R.map(
        (type) => ({ type, value: query[type] }),
        R.filter((ft) => query[ft], this.facetTypes)
      )
    },
    query () {
      try {
        return lucene.toString(R.reduce(
          (left, f) => ({
            operator: 'AND',
            left,
            right: {
              field: `${f.type}_t`,
              term: f.value,
              quoted: true
            }
          }), {
            left: lucene.parse(this.q || 'type_s:object'),
            parenthesized: true
          },
          this.facets
        ))
      } catch (e) {
        return undefined
      }
    },

    objects () {
      return (this.results.results || [])
    },

    total () {
      return this.results.total
    },

    nonEmptyParentObjectFacets () {
      const parentFacets = this.$parent.facetTypes
      const parentObjectFacets = this.$parent.objectFacets
      return parentFacets.filter(f => parentObjectFacets[f].length > 1)
    },

    objectList () {
      return this.objects.slice(0, this.limit)
    },

    objectFacets () {
      const all = {}
      for (const type of this.facetTypes) {
        const facet = this.results[type] || {}
        const values = R.sortBy(R.toLower, R.keys(facet))

        let max = 2
        let min = 1
        for (const value of values) {
          const count = facet[value]
          max = Math.max(max, count)
          min = Math.min(min, count)
        }

        all[type] = R.sortBy(
          R.pipe(R.prop('value'), R.toLower),
          R.map(
            value => {
              const count = facet[value]
              let classes = {
                'is-medium': false,
                'is-large': false,
                'is-info': false,
                'is-dark': false,
                'is-italic': count === this.total
              }
              if (values.length > 1) {
                switch (Math.round((count - min) * 2 / (max - min))) {
                  case 2:
                    classes['is-medium'] = true
                    classes['is-info'] = true
                    break
                  case 1:
                    classes['is-dark'] = true
                    break
                }
              }
              return { value, count, classes }
            },
            values
          )
        )
      }
      return all
    }
  },

  methods: {
    facetsRoute (base, facets) {
      return this.normalizeRoute({ ...base, query: { ...base.query, ...facets } })
    },

    clearFacets (base) {
      return this.facetsRoute(base, R.reduce(
        (all, f) => ({ ...all, [f]: '' }),
        {},
        this.facetTypes
      ))
    },

    async executeQuery () {
      const { query, results, lang } = this

      if (query === undefined) return
      if (results.query === query && results.lang === lang) return

      this.loading = true
      try {
        const params = { query, lang, limit: 2000 }
        const response = await this.$http.get('/api/search', { params })
        this.results = await response.json()
        this.limit = 2000
      } catch (e) {
        // ignore
      }
      this.loading = false
    }
  }
}
