import sparqljs from 'sparqljs'
import { qn, prefixes } from './ns'

const { assign } = Object

export function nt (subject, predicate, object) {
  subject = qn(subject)
  predicate = qn(predicate)
  object = qn(object)

  return { subject, predicate, object }
}

export function nq (subject, predicate, object, graph) {
  subject = qn(subject)
  predicate = qn(predicate)
  object = qn(object)
  graph = qn(graph)

  return { subject, predicate, object, graph }
}

export function select (obj) {
  return new sparqljs.Generator().stringify(assign({
    type: 'query', queryType: 'SELECT', prefixes
  }, obj))
}

export function construct (obj) {
  return new sparqljs.Generator().stringify(assign({
    type: 'query', queryType: 'CONSTRUCT', prefixes
  }, obj))
}

export function union (...patterns) {
  return { type: 'union', patterns }
}

export function path (pathType, ...items) {
  return { type: 'path', pathType, items: items.map(qn) }
}

export function p (...items) {
  return path('/', ...items)
}

export function invP (...items) {
  return path('^', ...items)
}

export function patterns (...triples) {
  return { type: 'bgp', triples }
}

export function values (...values) {
  return { type: 'values', values }
}

export function optional (...patterns) {
  return { type: 'optional', patterns }
}
