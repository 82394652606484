import R from 'ramda'

import iri2iso from '../lib/languages.json'

export const list = R.cond([
  [R.isNil, R.always([])],
  [Array.isArray, R.identity],
  [R.isEmpty, R.always([])],
  [R.T, (a) => [ a ]]
])

export const path = R.curry((path, objects) => R.reduce(
  (vals, key) => R.flatten(R.map(
    R.pipe(R.prop(key), R.defaultTo([])), vals
  )),
  list(objects),
  path
))

export const translate = (...langs) => (values) => {
  const translations = {}
  for (const value of values) {
    const title = R.prop('ecrm:P3_has_note', value)
    if (!title) {
      continue
    }
    const langId = R.path(['ecrm:P72_has_language', '@id'], value)
    const lang = iri2iso[langId || ''] || 'xx'
    translations[lang] = title
  }
  for (const lang of langs) {
    if (translations[lang]) return translations[lang]
  }
  for (const lang of Object.keys(translations).sort()) {
    return translations[lang]
  }
  return undefined
}
