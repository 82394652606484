import * as d3 from 'd3'
import cloud from 'd3-cloud'

export default {
  name: 'foko-word-cloud',
  props: ['words'],

  mounted () {
    this.renderCloud()
  },

  methods: {
    drawCloud (words) {
      const width = this.$el.offsetWidth
      const height = this.$el.offsetHeight

      d3.select(this.$el).select('svg').remove()

      d3.select(this.$el).append('svg')
        .attr('width', `${width}px`)
        .attr('height', `${height}px`)
        .append('g')
        .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')
        .selectAll('text').data(words).enter().append('text')
        .style('font-size', ({ size }) => `${size}px`)
        .style('fill', ({ color }) => color)
        .attr('transform', ({ x, y, rotate }) => `translate(${x},${y})rotate(${rotate})`)
        .text(({ text }) => text)
        .on('click', (word) => this.$emit('word-click', word))
    },

    renderCloud () {
      const width = this.$el.offsetWidth
      const height = this.$el.offsetHeight

      const max = this.words.reduce((max, { count }) => Math.max(max, count), 0)
      const min = this.words.reduce((min, { count }) => Math.min(min, count), max)

      const sizeScale = d3.scaleLog()
        .domain([min, max])
        .range([15, 40])

      const colorScale = d3.scaleLog()
        .domain([min, max])
        .range(d3.schemeCategory10)

      for (const word of this.words) {
        word.text = `${word.value} (${word.count})`
        word.size = sizeScale(word.count)
        word.color = colorScale(word.count)
      }

      cloud()
        .size([width, height])
        .words(this.words)
        .padding(5)
        .rotate(() => 0 /* ~~(Math.random() * 2) * 90 */)
        .fontSize(({ size }) => size)
        .on('end', this.drawCloud.bind(this))
        .start()
    }
  }
}
