export default {
  name: 'colophon',

  computed: {
    langActive () {
      return {
        de: { 'is-active': this.lang === 'de' },
        en: { 'is-active': this.lang === 'en' }
      }
    }
  }
}
