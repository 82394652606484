import R from 'ramda'

import { path, translate } from './model-helper'

const gndId2iso = {
  '4303381-7': 'cs',
  '4046496-9': 'pl',
  '4055297-4': 'sk',
  '4074266-0': 'lt',
  '4061496-7': 'uk',
  '4079143-9': 'ru',
  '4050939-4': 'ro',
  '4078541-5': 'hu',
  '122139-5': 'lv',
  '4076899-5': 'ru'
}

const countryToLanguage = R.pipe(
  path(['ecrm:P1_is_identified_by', 'ecrm:P3_has_note']),
  R.map((id) => gndId2iso[id]),
  R.find(R.complement(R.isNil)),
  R.defaultTo('de')
)

const addressComponent = (name) => R.pipe(
  R.filter(R.has(name)),
  R.map(R.prop(name)),
  R.head
)

export const address = (location) => {
  const ids = path(['ecrm:P87_is_identified_by'], location)

  const street = addressComponent('foko:has_street')(ids)
  const house = addressComponent('foko:has_house_no')(ids)
  const postal = addressComponent('foko:has_ZIP')(ids)

  let city = path(['ecrm:P87i_identifies'], ids)
  let country = path(['ecrm:P89_falls_within'], city)
  const translator = translate(countryToLanguage(country))

  city = translator(path(['ecrm:P87_is_identified_by'], city))
  country = translator(path(['ecrm:P87_is_identified_by'], country))

  return R.join(', ', R.reject(R.either(R.isNil, R.isEmpty), [
    R.join(' ', R.reject(R.isNil, [street, house])),
    R.join(' ', R.reject(R.isNil, [postal, city])),
    country
  ]))
}

export const parseLatLon =
  R.pipe(R.split(/,/), R.map(parseFloat), ([lat, lon]) => ({ lat, lon }))

export const coordinates = (location) => {
  return R.head(R.map(parseLatLon, path(
    ['ecrm:P1_is_identified_by', 'ecrm:P3_has_note'], location
  )))
}

export const objectAddress = (object) => address(
  path(['ecrm:P53_has_former_or_current_location'], object)
)
