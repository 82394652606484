const { assign, keys } = Object

export const context = {
  'rdf': 'http://www.w3.org/1999/02/22-rdf-syntax-ns#',
  'rdfs': 'http://www.w3.org/2000/01/rdf-schema#',
  'ecrm': 'http://erlangen-crm.org/140617/',
  'getty': 'http://vocab.getty.edu/ontology#',
  'aat': 'http://vocab.getty.edu/aat/term/',
  'skos': 'http://www.w3.org/2004/02/skos/core#',
  'gnd': 'http://d-nb.info/gnd/',
  'gndo': 'http://d-nb.info/standards/elementset/gnd#',
  'foko': 'http://id.gnm.de/ont/foko/'
}

export const prefixes = assign({ '': 'http://foko2014.gnm.de/content/' }, context)

export function nc (iri) {
  const prefix = keys(prefixes)
    .find(prefix => iri.startsWith(prefixes[prefix]))

  if (prefix === undefined) {
    return iri
  }
  const ns = (prefix === undefined ? undefined : prefixes[prefix])
  return [prefix, ns ? iri.substring(ns.length) : iri]
}

export function pn (iri) {
  return nc(iri).filter(c => c).join(':')
}

export function qn (prefixedName) {
  const match = /(?:^|"\^\^)([^:/#"'^_]*):[^/]*$/.exec(prefixedName)

  if (!match) {
    return prefixedName
  }
  const [ , prefix ] = match
  const { index } = match

  const base = prefixes[prefix]

  if (base === undefined) {
    return prefixedName
  }

  if (index === 0) {
    return [ base, prefixedName.substr(prefix.length + 1) ].join('')
  }

  // The match index is non-zero when expanding a literal's type
  return [
    prefixedName.substr(0, index + 3),
    base,
    prefixedName.substr(index + prefix.length + 4)
  ].join('')
}
