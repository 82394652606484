import { path } from './model-helper'

export const iiifId = (id) => id.replace(/\s/g, '_')

const imageBase = 'http://foko2014.gnm.de/sites/default/files/'

export const imageUrlToId = (url) => {
  if (!url.startsWith(imageBase)) return undefined

  url = url.substring(imageBase.length)
  if (url.includes('/')) return undefined

  const dot = url.lastIndexOf('.')
  if (dot < 0 || dot >= (url.length - 1)) return undefined

  return iiifId(url.substring(0, dot))
}

export const objectImages = (objects) => {
  const images = []
  const imageIndex = {}
  for (const expression of path(['ecrm:P138i_has_representation'], objects)) {
    for (const manifestation of path(['ecrm:P65i_is_shown_by'], expression)) {
      for (let image of path(['ecrm:P1_is_identified_by', 'ecrm:P3_has_note'], manifestation)) {
        image = imageUrlToId(image)
        if (!image || imageIndex[image]) continue
        images.push({ expression, manifestation, image })
        imageIndex[image] = true
      }
    }
  }
  return images
}
